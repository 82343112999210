<template>
  <div>
    <div class="row">
      <div class="col-md-5 mx-auto">
        <div class="card mb-5">
          <div class="card-body">
            <div class="text-center my-4">
              <img src="@/assets/logo.png" alt="ared-logo" class="w-50" />
            </div>
            <h4>OTP</h4>
            <p class="mb-5">
              Enter the One Time Password token sent to your email.
            </p>
            <form method="post" @submit.prevent="submitOtp">
              <div class="form-group text-left">
                <label for="otp">OTP:</label>
                <input
                  v-model.trim="otp"
                  type="text"
                  name="otp"
                  autofocus
                  class="form-control"
                  id="otp"
                  placeholder="OTP"
                  required
                />
                <small class="text-danger">{{ errors.first("otp") }}</small>
              </div>

              <div class="col-sm-12 my-5">
                <button
                  type="submit"
                  class="btn btn-primary w-50"
                  :disabled="submitting"
                >
                  {{ submitting ? "LOADING..." : "SUBMIT" }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "OtpForm",
  data() {
    return {
      submitting: false,
      otp: "",
      error: ""
    };
  },
  methods: {
    ...mapActions(["verifyTwoFaToken"]),
    submitOtp: function() {
      this.submitting = true;
      this.verifyTwoFaToken({ token: this.otp 
      })
      .then((result)=>{
          if (result==="failed"){
            this.submit= 'Log In'
            this.submitting = false
          }
        })
        
    }
  }
};
</script>
