<template>
  <div>
    <OtpForm />
  </div>
</template>
<script>
import OtpForm from "@/components/Auth/OtpForm";
export default {
  components: { OtpForm }
};
</script>
